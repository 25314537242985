import ListItem from "./ListItem";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Editor } from "@tinymce/tinymce-react";
import { AppContext } from "../AppContext";
import { toast } from "react-toastify";
import { callAdminApi } from "../API/ApiInterface";
import swal from "sweetalert";
import LoadingSpinner from "../Spinner";
import Pagination from "../Pagination";
import { confirmAlert } from "react-confirm-alert"; // Import
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";

export default function Email() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [allLeads, setAllelads] = useState([]);
  const [leadId, setSearchLeadId] = useState("");
  const [showEmailDiv, setShowEmailDiv] = useState(true);
  const [alltempaltes, setAllTemplates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const { checkItems, setCheckItems } = useContext(AppContext);
  const [nPages, setNPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const allMemberStatus = useSelector((state) => state.memberStatus.data);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(200);
  const editorRef = useRef(null);
  const [availableConfirmationCounts, setAvailableConfirmationCounts] = useState([]);
  const [selectedConfirmationCounts, setSelectedConfirmationCounts] = useState([]);

  // useEffect(() => {
  //   if (selectAll) setCheckItems(contacts.map((item) => item.id));
  //   else setCheckItems([]);
  // }, [selectAll]);

  const setLeadIdFxn = (leadId) => {
    setSearchLeadId(leadId);
  };

  const sendTemplate = (nameT, content) => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/templates/save",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          content: content,
          name: nameT,
          templateType: showEmailDiv ? 1 : 2,
        }),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          alltempaltes.push(data.body);

          setAllTemplates([...alltempaltes]);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const saveTemplate = () => {
    swal({
      title: "Enter name of template:",
      content: "input",
    }).then((message) => {
      if (message !== "" && message !== null) {
        let content = "";
        if (!showEmailDiv) {
          content = document.getElementById("smsContent").value;
        } else {
          content = editorRef.current.getContent();
        }
        if (content) sendTemplate(message, content);
        else
          return toast.warning("Content should not be empty", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    });
  };

  const sendEmail = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container">
            <h1>Are you sure?</h1>
            <p>You want to send this message?</p>
            <p className="fs-5">Total selected users: {checkItems.length}</p>
            <button className="btn btn-danger" onClick={onClose}>
              No
            </button>
            <button
              className="mx-2 btn btn-success"
              onClick={() => {
                if (checkItems.length > 0) {
                  sendEmailApi();
                } else {
                  toast.error("Please select at least 1 user", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
                onClose();
              }}
            >
              Yes, Send it!
            </button>
          </div>
        );
      },
    });
  };

  const sendSMSAPI = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container">
            <h1>Are you sure?</h1>
            <p>You want to send this message?</p>
            <p className="fs-5">Total selected users: {checkItems.length}</p>
            <button className="btn btn-danger" onClick={onClose}>
              No
            </button>
            <button
              className="mx-2 btn btn-success"
              onClick={() => {
                if (checkItems.length > 0) {
                  acceptFunc();
                } else {
                  toast.error("Please select at least 1 user", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
                onClose();
              }}
            >
              Yes, Send it!
            </button>
          </div>
        );
      },
    });

    const acceptFunc = () => {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/users/sendBulkSMS",
        "POST",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({
            content: document.getElementById("smsContent").value,
            ids: checkItems,
          }),
        }
      )
        .then(async (data) => {
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    };
  }, [checkItems]);


  function setTextToCurrentPos(element, text_to_insert) {
    var curPos = element.selectionStart;
    let x = element.value;
    element.value = x.slice(0, curPos) + text_to_insert + x.slice(curPos);
  }

  const appendReference = (value) => {
    if (!showEmailDiv) {
      const element = document.getElementById("smsContent");
      setTextToCurrentPos(element, `[[${value}]]`);
    } else {
      if (editorRef.current) {
        editorRef.current.execCommand(
          "mceInsertContent",
          false,
          `[[${value}]]`
        );
      }
    }
  };
  const getAllTempaltes = () => {
    setLoading(true);

    callAdminApi(
      process.env.REACT_APP_BASEURL +
      "/admin/templates/all?templateType=" +
      (showEmailDiv ? 1 : 2),
      "GET",
      {
        type: "json",
        auth: true,
      }
    )
      .then(async (data) => {
        setLoading(false);

        if (data.code === 200) {
          const result = data.body;

          const array = [];

          result.map((item) => {
            return array.push({ id: item.id, name: item.name });
          });
          setAllTemplates([...array]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadTemplate = (id) => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/templates/" + id,
      "GET",
      {
        type: "json",
        auth: true,
      }
    )
      .then(async (data) => {
        setLoading(false);
        if (data.code === 200) {
          let content = data.body?.content;
          let title = data.body?.name;
          if (!showEmailDiv) {
            document.getElementById("smsContent").value = content.replace(
              /<[^>]*>/g,
              ""
            );
          } else {
            content = editorRef.current.setContent(content);
            setSubject(title);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getFilterInfo = useCallback(() => {
    fetch(process.env.REACT_APP_BASEURL + "/users/getFilterInfo?admin=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
    })
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          const filters = data.body;

          setAllelads(filters?.leadInfo?.map((item) => ({ id: item.id, name: item.name })) || []);

          // Set confirmation counts
          setAvailableConfirmationCounts(filters?.confirmationCounts?.map((item) => item.confirmationCount) || []);
        } else {
          console.error("Unexpected response code:", data.code);
        }
      })
      .catch((err) => console.error("Error fetching filter info:", err));
  }, []);

  const sendEmailApi = useCallback(() => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/users/sendBulkEmail",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          content: editorRef.current.getContent(),
          ids: checkItems,
          subject: subject,
        }),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  }, [checkItems, subject]);

  useEffect(() => {
    getFilterInfo();
  }, []);
  useEffect(() => {
    getAllTempaltes();
  }, [showEmailDiv]);

  useEffect(() => {
    setLoading(true);
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/users/all", "POST", {
      body: JSON.stringify({
        search: search,
        zipCode: "",
        leadId: leadId,
        mapStatus: selected.map((item) => item.id),
        confirmationCount: selectedConfirmationCounts.map((item) => item.value),
        checkPermissions: 1,
        page: currentPage,
        limit: recordsPerPage,
        selectAll,
      }),
      type: "json",

      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        if (data.code === 200) {
          console.log("Filtered Data Received:", data.body);
          const result = data.body;
          setContacts([...result.rows]);
          setTotalRecords(result.count);
          const numberPages = Math.ceil(result.count / recordsPerPage);

          const array = [];
          result.rows.map((item) => {
            return array.push(item.id);
          });
          if (selectAll) {
            setNPages(1);
            setCheckItems([...array]);
          } else {
            setCheckItems([]);
            setNPages(numberPages);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .catch((e) => console.log("API Error:", e))
      .finally(() => setLoading(false));
  }, [search, leadId, selected, selectedConfirmationCounts, currentPage, selectAll]);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div className="bodyNav  row ">
        <div className="col-md-7 border  mx-2  my-3">
          <div className="col-md-12 row">
            <div className="col-md-3">
              <select
                onChange={(e) => setLeadIdFxn(e.target.value)}
                className="form-select select2 mt-2 p-2"
                aria-label=".form-select-lg example"
              >
                <option value="">Select Lead</option>
                {allLeads.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>



            <div className="col-md-3 mt-2">
              <MultiSelect
                options={allMemberStatus}
                onChange={setSelected}
                labelledBy="Member"
                overrideStrings={{
                  selectSomeItems: "Select Member Status",
                  allItemsAreSelected: "All status are selected.",
                }}
                value={selected}
              />
            </div>

            <div className="col-md-3 mt-2">
              <MultiSelect
                options={availableConfirmationCounts.map((num) => ({ label: num.toString(), value: num }))}
                onChange={setSelectedConfirmationCounts}
                labelledBy="Confirmation Count"
                overrideStrings={{
                  selectSomeItems: "Select Confirmation Count",
                  allItemsAreSelected: "All confirmation counts are selected.",
                }}
                value={selectedConfirmationCounts}
              />
            </div>

            <div className="col-md-6">
              <input
                type="text"
                placeholder="Search by (name,contact,email,zipcode)"
                value={search}
                className="form-control mt-2 p-2"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>

          <div className="row mt-3  float-end">
            <div className="col-md-11 ">
              <button
                className={
                  selectAll
                    ? "btn btn-success float-end text-capitalize"
                    : "btn btn-gray float-end text-capitalize"
                }
                onClick={() => setSelectAll(!selectAll)}
              >
                Select All - {totalRecords} entries
              </button>
              <p className="text-danger float-end ">
                <i style={{ fontSize: "13px" }}>
                  This action may take more than the expected or usual amount of
                  time to complete{" "}
                </i>
              </p>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <ListItem contacts={contacts} />
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>

        <div className="col-md-5 row my-3">
          <div className="col-md-4">
            <select
              onChange={(e) => loadTemplate(e.target.value)}
              className="form-select mt-2 p-2"
              aria-label=".form-select-lg example"
            >
              <option value="">Select Template</option>
              {alltempaltes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-7">
            <button
              style={{ display: showEmailDiv ? "inline" : "none" }}
              onClick={() => setShowEmailDiv(false)}
              className="btn col-lg-12 btn-warning my-1"
            >
              Send SMS
            </button>
            <button
              style={{ display: !showEmailDiv ? "inline" : "none" }}
              onClick={() => setShowEmailDiv(true)}
              className="btn col-lg-12 btn-info my-1 "
            >
              Send Email
            </button>
          </div>
          <div
            style={{ display: !showEmailDiv ? "inline" : "none" }}
            className="col-md-7 mt-2"
          >
            <textarea
              id="smsContent"
              className="form-control "
              placeholder="Write your SMS content here..."
              maxLength={300}
              rows="15"
            ></textarea>
            <button
              className="ms-1 my-3 btn btn-success"
              onClick={() => sendSMSAPI()}
              size="lg"
            >
              Send SMS
            </button>

            <button
              className="ms-1 my-3 btn btn-success"
              onClick={() => saveTemplate()}
              size="lg"
            >
              Save Template
            </button>
          </div>

          <div
            style={{ display: showEmailDiv ? "inline" : "none" }}
            className="col-md-9 mt-2"
          >
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              className="form-control mt-2 mb-2  p-2"
              onChange={(e) => setSubject(e.target.value)}
            />
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue="<p>Write your customized email content here</p>"
              apiKey={process.env.REACT_APP_TINY_KEY}
              init={{
                height: "500px",
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                mergetags_list: [
                  { value: "First.Name", title: "First Name" },
                  { value: "Email", title: "Email" },
                ],
                ai_request: (request, respondWith) =>
                  respondWith.string(() =>
                    Promise.reject("See docs to implement AI Assistant")
                  ),
              }}
            />

            <button
              className="ms-1 my-3 btn btn-success"
              onClick={() => sendEmail()}
              size="lg"
            >
              Send Email
            </button>

            <button
              className="ms-1 my-3 btn btn-secondary"
              onClick={() => saveTemplate()}
              size="lg"
            >
              Save Template
            </button>
          </div>

          <div className="col-md-3">
            <button
              onClick={() => appendReference("name")}
              className="btn btn-sm btn-info mt-1"
            >
              Name
            </button>
            <button
              onClick={() => appendReference("email")}
              className="btn btn-sm btn-info mt-1"
            >
              Email
            </button>
            <button
              onClick={() => appendReference("contact")}
              className="btn btn-sm btn-info mt-1"
            >
              Contact
            </button>
            <button
              onClick={() => appendReference("address")}
              className="btn btn-sm btn-info mt-1"
            >
              Address
            </button>
            <button
              onClick={() => appendReference("city")}
              className="btn btn-sm btn-info mt-1"
            >
              City
            </button>
            <button
              onClick={() => appendReference("zipCode")}
              className="btn btn-sm btn-info mt-1"
            >
              Zip Code
            </button>

            <button
              onClick={() => appendReference("memberStatus")}
              className="btn btn-sm btn-info mt-1"
            >
              Member Status
            </button>

            <button
              onClick={() => appendReference("userId")}
              className="btn btn-sm btn-info mt-1"
            >
              User Id
            </button>

            <button
              onClick={() => appendReference("UNSUBSCRIBE")}
              className="btn btn-sm btn-info mt-1"
            >
              Unsubscribe
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
