import React from "react";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
export default function AnalysisItem({ contacts }) {
  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "500px" }}
    >
      <table>
        <thead>
          <tr>
            <th>Sr. No</th>

            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Zip Code</th>
            <th>Household Size</th>
            <th>Lead Name</th>
            <th>Member Status</th>
            <th>Verified By</th>
            <th>Verified At</th>
            <th>Scan Via</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((vData, index) => (
            <tr key={"_" + index + "_" + vData.user?.id}>
              <td>{index + 1}</td>
              <td>{vData.user?.firstName + " " + vData.user?.lastName}</td>
              <td>{vData.user?.email}</td>
              <td>
                +{vData.user?.countryCode + "-" + vData?.user?.phoneNumber}
              </td>
              <td>{vData.user?.zipCode}</td>
              <td>{vData.count}</td>
              <td>
                {vData.user?.zipInfo?.lead
                  ? vData.user?.zipInfo?.lead?.name
                  : "NA"}
              </td>
              <td>{vData.user?.mapStatusInfo?.statusName}</td>
              <td>
                {vData.verifiedByUser
                  ? `${vData.verifiedByUser.firstName} ${vData.verifiedByUser.lastName}`
                  : "N/A"}
              </td>{/* Display Verified By */}
              <td>{new Date(vData.createdAt).toLocaleString()}</td>
              <td>{vData.scanType === 2 ? "Mobile/UserId" : "QR Code"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
