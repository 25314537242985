import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import Modal from "react-modal";
import PaymentInfo from "./PaymentInfo";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import EditUser from "./EditUser";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";
import { customStyles } from "../../CustomStyle";
export default function UserItem({ contacts }) {
  const { setUserDeleted, userUpdated } = useContext(AppContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [userDetail, setuserDetail] = useState({});
  const [isLoading, setLoading] = useState(false);



  const editDetails = (id) => {
    const findItem = contacts.find((item) => item.id === id);

    if (findItem) {
      setuserDetail(findItem);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    setModalOpen(false);
  }, [userUpdated]);

  const deleteuser = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      callAdminApi(process.env.REACT_APP_BASEURL + "/users/delete", "DELETE", {
        type: "json",
        auth: true,
        body: JSON.stringify({
          id: id,
        }),
        // body:JSON.stringify({leads:selctedIds})
      })
        .then(async (data) => {
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setUserDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };
  const fetchPaymentInfo = (id) => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/users/fetchPaymentInfo",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          id: id,
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          setLoading(false);

          const result = data.body;
          if (result) {
            const findItem = contacts.find((item) => item.id === id);
            setuserDetail(findItem);
            setPaymentInfo(result);
            setPaymentModal(true);
          } else {
            toast.info("No Payment Info", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setLoading(false);

        console.log(e);
        toast.success(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "500px" }}
      >
        <table>
          <thead>
            <tr>
              <th>Sr. No</th>

              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Zip Code</th>
              <th>City</th>
              <th>Address</th>
              <th>University</th>
              <th>Household Size</th>
              <th>Lead Name</th>
              <th>Member Status</th>
              <th>Admin Info</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((user, index) => (
              <tr key={"_" + index + "_" + user.id}>
                <td>{index + 1}</td>
                <td>{user.firstName + " " + user.lastName}</td>
                <td>{user.email}</td>
                <td>+{user.countryCode + "-" + user.phoneNumber}</td>
                <td>{user.zipCode}</td>
                <td>{user.city}</td>
                <td>{user.address}</td>
                <td>{user.university}</td>
                <td>{user.householdSize}</td>
                <td>{user.zipInfo?.lead ? user.zipInfo?.lead?.name : "NA"}</td>
                <td>{user.mapStatusInfo?.statusName}</td>
                <td>
                  {user.adminInfo
                    ? `${user.adminInfo?.firstName} ${user.adminInfo?.lastName}(${user.adminInfo?.email})`
                    : "NA"}
                </td>

                <td>
                  <button
                    onClick={(e) => deleteuser(e, user.id)}
                    className="btn btn-xs btn-danger"
                  >
                    Delete
                  </button>{" "}
                  <button
                    onClick={(e) => editDetails(user.id)}
                    className="btn btn-info  mx-1 btn-xs"
                  >
                    Edit
                  </button>
                  <button
                  style={{display:user.isPaidMember?"inline":"none"}}
                    onClick={(e) => fetchPaymentInfo(user.id)}
                    className="btn btn-success  mx-1 btn-xs"
                  >
                    Payment Info
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          <button onClick={(e) => setModalOpen(false)}>Close</button>

          <EditUser requestType={"admin"} userDetail={userDetail}></EditUser>
        </Modal>

        <Modal
          isOpen={paymentModal}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          <button onClick={(e) => setPaymentModal(false)}>Close</button>

          <PaymentInfo
            userDetail={userDetail}
            paymentDetail={paymentInfo}
          ></PaymentInfo>
        </Modal>
      </div>
    </>
  );
}
