import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";

export default function UserPermission() {
  const [permissionList, updateCheckedPerm] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [allOptions, setOptions] = useState([]);
  const [resetInterval, setResetInterval] = useState("30d"); // Default value
  const [resetOptions, setResetOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/getAutoResetInterval", "GET", { auth: true })
      .then((data) => {
        setLoading(false);
        console.log("API Response:", data);
  
        if (data && data.resetInterval && data.options) {
          console.log("Reset options received:", data.options);
          setResetInterval(data.resetInterval);
          setResetOptions(data.options);
        } else {
          console.error("Error: Invalid response format", data);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("API Error:", e);
      });
  }, []);
 
   // Function to update the interval
  const updateResetInterval = () => {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/setAutoResetInterval",
        "POST",
        {
          auth: true,
          type: "json",
          body: JSON.stringify({
            resetInterval: resetInterval,
          }),
          // body:JSON.stringify({leads:selctedIds})
        }
      )
        .then(async (data) => {
          if (data.message) {
            toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
   
  useEffect(() => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/useroptions/all",
      "GET",
      { type: "json", body: null, auth: true }
    )
      .then(async (data) => {
        if (data.code === 200) {
          setOptions([...data.body]);

          const array = [];
          data.body?.map((item) => {
            return array.push({
              id: item.id,
              name: item.option,
              public: item.public,
            });
          });

          updateCheckedPerm([...array]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const updatePermissionApi = () => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/useroptions/update",
      "PUT",
      {
        type: "json",
        body: JSON.stringify({ options: permissionList }),
        auth: true,
      }
    )
      .then(async (data) => {
        setLoading(false);
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
      });
  };

  const updateEditPermission = (checked, moduleId) => {
    let indexF = permissionList.findIndex((ip) => ip.id === moduleId);

    if (indexF < 0) {
      permissionList.push({ id: moduleId, public: checked ? 1 : 0 });
    } else {
      permissionList[indexF] = { id: moduleId, public: checked ? 1 : 0 };
    }

    console.log(permissionList);
    updateCheckedPerm([...permissionList]);
  };

  const checkedData = (moduleId) => {
    let checked = "";
    const findIndex = permissionList.findIndex(
      (it) => it.id === moduleId && it.public === 1
    );

    if (findIndex >= 0) checked = "checked";
    return checked;
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav container">
        <div className="col-md-12 row my-3">
         
          <div style={{ margin: "0 auto",height: "700px" }}  className="infowindow col-md-6 border overflow-auto">
            <h3 className="mt-5">User Edit Permission</h3>
            <hr></hr>
            
            {/* Auto-Reset Interval Dropdown */}            
              <div className="col-md-12 mt-3 mx-5 text-start">
                <label className="fs-5">Auto-Reset Interval:</label>
                <select className="form-control" value={resetInterval} onChange={(e) => setResetInterval(e.target.value)}>
                  {resetOptions.map((option) => (
                    <option key={option} value={option}>
                      {option === "midnight" ? "Every Midnight" : option === "1w" ? "In 1 Week" : option === "resetNow" ? "Reset Now" : option}
                    </option>
                  ))}
                </select>
              </div> 

            <button onClick={updateResetInterval} className="btn btn-success m-3">
              Update Reset Interval
            </button>
            
            {allOptions?.map((item, index) => (
              <div className="col-md-12 mt-3 mx-5 text-start">
                <input
                  checked={checkedData(item.id)}
                  onChange={(e) =>
                    updateEditPermission(e.target.checked, item.id)
                  }
                  id={item.moduleId}
                  className="form-check-input  mt-1"
                  type="checkbox"
                />
                <span className="fs-5">{item?.option}</span>
                <br></br>
              </div>
            ))}
            <hr></hr>
            <button
              onClick={updatePermissionApi}
              className="btn btn-success m-3"
            >
              {" "}
              Update Permissions
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
